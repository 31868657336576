.search-from-wrap{
    max-width:450px;
    padding:10px;
    margin:0 auto;
    display: flex;
    align-items: center;
}
.search-from-wrap .search-input{
    padding:10px;
    background-color:#ffffff;
    border:1px solid #cccccc;
    width:100%;
}
.search-from-wrap .search-input:focus{
    outline:none;
}
.search-from-wrap .search-button{
    width:100%;
    background-color:#ffae00;
    color:#000000;
    border:0;
    padding:11px;
    width:100px;
}