.food-main-wraper{
    display: flex;
    flex-wrap: wrap;
    max-width:1280px;
    margin:0 auto;
}

.foodBox{
    margin-right: 15px;
    margin-bottom: 15px;
    width:calc((100% / 3) - 15px);
}
.foodImage img{
    width:100%;
}

.food-main-wraper .foodBox:nth-child(3n){
    margin-right:0;
}